import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useApm } from '../../hooks/apm/apm';
import Loading from '../../components/Loading';
import { useLocation } from 'react-router-dom';
import EngineChangeTable from '../../components/apm/EngineChangeTable';
import APM from '../../types/apm/APM';
import { ApmDataUpdateResponse } from '../../services/apm';
import EngineChangeResult from '../../components/apm/EngineChangeResult';

const EngineChange: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pageErrorState = useState<string>('');
  const [pageError] = pageErrorState;
  const {
    apms,
    types,
    fetchApmData,
    complete: loadedApm,
    filterApm,
    updateApm,
    updateEngineChange,
  } = useApm(pageErrorState);
  const [selectedType, setSelectedType] = useState<string>(searchParams.get('actype') || '');
  const [apmUpdateResponse, setApmUpdateResponse] = useState<ApmDataUpdateResponse>();

  const getSubmitHandler = (apm: APM) => {
    return async (e: any) => {
      e.preventDefault();
      if (!apm.endDate) {
        window.alert(`Please enter end date of ${apm.Reg}`);
        return;
      }
      if (!apm.EC1 && !apm.EC2 && !apm.EC3 && !apm.EC4) {
        window.alert(`Please select engine change of ${apm.Reg}`);
        return;
      }
      if (!window.confirm(`Confirm to update engine change of ${apm.Reg}?`)) return;
      const response = await updateEngineChange(apm);
      setApmUpdateResponse(response);
    };
  };

  useEffect(() => {
    fetchApmData();
    setApmUpdateResponse(undefined);
  }, [selectedType]);

  const filteredApms = filterApm({
    type: selectedType,
  });

  const totalAircraft = filteredApms.length;

  return (
    <div>
      <Helmet>
        <title>{`APM Engine Change Page`}</title>
        <meta name='description' content='APM Engine Change Page' />
      </Helmet>
      <h1>Performance Factors Update for Engine Change</h1>
      <Loading complete={loadedApm} error={pageError}>
        <table className='apm-filter'>
          <tbody>
            <tr>
              <td>Aircraft Type</td>
              <td>
                <select
                  name='actype'
                  defaultValue={selectedType}
                  onChange={(e) => setSelectedType(e.target.value)}
                >
                  <option value={''}>- Please Select -</option>
                  {types.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
          </tbody>
        </table>

        {apmUpdateResponse && <EngineChangeResult {...apmUpdateResponse} />}

        {selectedType && <p>{`Number of aircraft: ${totalAircraft}`}</p>}

        {selectedType && (
          <p>
            Note: <br />
            1. These factors do not reflect any change due to MEL/CDL.
            <br />
            2. Factors are adjusted from data issued on "Last Issue Date", if "Last Eng Change Date"
            is displayed.
            <br />
          </p>
        )}

        {selectedType && (
          <EngineChangeTable
            apms={filteredApms}
            acType={selectedType}
            updateApm={updateApm}
            getSubmitHandler={getSubmitHandler}
          />
        )}
      </Loading>
    </div>
  );
};

export default EngineChange;
