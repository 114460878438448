import config from '../../config';
import { useUserContext } from '../../providers/UserProvider';
import APM from '../../types/apm/APM';
import { isFourEng, populateCustomData } from './apmTableConfigs';
import { getTableConfig } from './engineChangeTableConfigs';

type EngineChangeTableProps = {
  apms: APM[];
  acType: string;
  updateApm: (reg: string, newApm: APM) => void;
  getSubmitHandler: (apm: APM) => (e: any) => void;
};

const EngineChangeTable = ({
  apms,
  acType,
  updateApm,
  getSubmitHandler,
}: EngineChangeTableProps) => {
  const { hasSomeRole } = useUserContext();
  const tableConfig = getTableConfig(acType);
  const fourEng = isFourEng(acType);
  const canEdit = hasSomeRole(config.APM_ADMIN_ROLES);
  return (
    <table className='apm-table apm-table-main'>
      <thead>
        <tr>
          {tableConfig.map((config, i) => (
            <th key={`apm_${i}`} dangerouslySetInnerHTML={{ __html: config.label }}></th>
          ))}
          <th>Eng #1 Change</th>
          <th>Eng #2 Change</th>
          {fourEng && (
            <>
              <th>Eng #3 Change</th>
              <th>Eng #4 Change</th>
            </>
          )}
          {canEdit && (
            <>
              <th>End date</th>
              <th></th>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {apms.map((apm, i) => {
          populateCustomData(apm);
          return (
            <tr key={`apm_${i}`}>
              {tableConfig.map((config) => (
                <td key={config.label} className={config.className}>
                  {config.display ? config.display(apm) : apm[config.key!]}
                </td>
              ))}

              <td>
                <input
                  type='checkbox'
                  checked={apm.EC1 === 1}
                  onChange={(e) => updateApm(apm.Reg, { ...apm, EC1: e.target.checked ? 1 : 0 })}
                  disabled={!canEdit}
                />
              </td>
              <td>
                <input
                  type='checkbox'
                  checked={apm.EC2 === 1}
                  onChange={(e) => updateApm(apm.Reg, { ...apm, EC2: e.target.checked ? 1 : 0 })}
                  disabled={!canEdit}
                />
              </td>
              {fourEng && (
                <>
                  <td>
                    <input
                      type='checkbox'
                      checked={apm.EC3 === 1}
                      onChange={(e) =>
                        updateApm(apm.Reg, { ...apm, EC3: e.target.checked ? 1 : 0 })
                      }
                      disabled={!canEdit}
                    />
                  </td>
                  <td>
                    <input
                      type='checkbox'
                      checked={apm.EC4 === 1}
                      onChange={(e) =>
                        updateApm(apm.Reg, { ...apm, EC4: e.target.checked ? 1 : 0 })
                      }
                      disabled={!canEdit}
                    />
                  </td>
                </>
              )}
              {canEdit && (
                <>
                  <td>
                    <input
                      style={{ width: '110px', textAlign: 'center', marginLeft: '5px' }}
                      type='date'
                      onChange={(e) => updateApm(apm.Reg, { ...apm, endDate: e.target.value })}
                    />
                  </td>
                  <td>
                    <button
                      style={{
                        borderRadius: '5px',
                      }}
                      onClick={getSubmitHandler(apm)}
                    >
                      {apm.Reg} Change
                    </button>
                  </td>
                </>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default EngineChangeTable;
