import APM from '../../types/apm/APM';
import { truncate } from '../../utils/MathUtils';
import { isFourEng } from './apmTableConfigs';

type ColumnConfig = {
  label: string;
  key?: keyof APM;
  display?: (apm: APM) => any;
  className?: string;
};

const TYPE: ColumnConfig = { label: 'Type', key: 'Type' };
const REG: ColumnConfig = { label: 'Registration', key: 'Reg' };
const CLIMB_ADJUSTED: ColumnConfig = {
  label: 'CLIMB',
  display: (apm) => truncate(apm.customData?.newClimb, 1),
};
const FF1: ColumnConfig = {
  label: 'FF#1',
  display: (apm) => truncate(apm.customData?.newFF1, 1),
};
const FF2: ColumnConfig = {
  label: 'FF#2',
  display: (apm) => truncate(apm.customData?.newFF2, 1),
};
const FF3: ColumnConfig = {
  label: 'FF#3',
  display: (apm) => truncate(apm.customData?.newFF3, 1),
};
const FF4: ColumnConfig = {
  label: 'FF#4',
  display: (apm) => truncate(apm.customData?.newFF4, 1),
};
const FF_ADJUSTED: ColumnConfig = {
  label: 'FF',
  display: (apm) => truncate(apm.customData?.newFF, 1),
};
const DRAG_ADJUSTED: ColumnConfig = {
  label: 'DRAG',
  display: (apm) => truncate(apm.customData?.newDrag, 1),
};
const PERF_ADJUSTED: ColumnConfig = {
  label: 'PERF',
  display: (apm) => truncate(apm.customData?.newPerf, 1),
};
const IDLE: ColumnConfig = { label: 'IDLE', key: 'Idle' };
const ISSUE_DATE: ColumnConfig = {
  label: 'Last Issue Date<br/>YYYY-MM-DD',
  display: (apm) => (apm.IssueDate === 'NULL' ? '' : apm.IssueDate),
};
const ENG_CHANGE_DATE: ColumnConfig = {
  label: 'Last Eng Change Date<br/>YYYY-MM-DD',
  display: (apm) => (apm.EngChgDate === 'NULL' ? '' : apm.EngChgDate),
};
const EC1: ColumnConfig = {
  label: 'Eng #1 Change',
  display: (apm) => 'EC1',
};
const EC2: ColumnConfig = {
  label: 'Eng #2 Change',
  display: (apm) => 'EC2',
};
const EC3: ColumnConfig = {
  label: 'Eng #3 Change',
  display: (apm) => 'EC3',
};
const EC4: ColumnConfig = {
  label: 'Eng #4 Change',
  display: (apm) => 'EC4',
};

const tableConfigTypeA: ColumnConfig[] = [
  TYPE,
  REG,
  FF1,
  FF2,
  FF_ADJUSTED,
  DRAG_ADJUSTED,
  CLIMB_ADJUSTED,
  PERF_ADJUSTED,
  IDLE,
  ISSUE_DATE,
  ENG_CHANGE_DATE,
];

const tableConfigTypeAFourEng: ColumnConfig[] = [
  TYPE,
  REG,
  FF1,
  FF2,
  FF3,
  FF4,
  FF_ADJUSTED,
  DRAG_ADJUSTED,
  CLIMB_ADJUSTED,
  PERF_ADJUSTED,
  IDLE,
  ISSUE_DATE,
  ENG_CHANGE_DATE,
];

const tableConfig: ColumnConfig[] = [
  TYPE,
  REG,
  FF1,
  FF2,
  FF_ADJUSTED,
  DRAG_ADJUSTED,
  CLIMB_ADJUSTED,
  PERF_ADJUSTED,
  ISSUE_DATE,
  ENG_CHANGE_DATE,
];

const tableConfigFourEng: ColumnConfig[] = [
  TYPE,
  REG,
  FF1,
  FF2,
  FF3,
  FF4,
  FF_ADJUSTED,
  DRAG_ADJUSTED,
  CLIMB_ADJUSTED,
  PERF_ADJUSTED,
  ISSUE_DATE,
  ENG_CHANGE_DATE,
];

function getTableConfig(acType: string) {
  const fourEng = isFourEng(acType);
  if (acType.startsWith('A') && acType !== 'A300-605') {
    if (fourEng) {
      return tableConfigTypeAFourEng;
    } else {
      return tableConfigTypeA;
    }
  } else {
    if (fourEng) {
      return tableConfigFourEng;
    } else {
      return tableConfig;
    }
  }
}

export type { ColumnConfig };
export { getTableConfig };
